/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import styles from './styles.module.scss';
import Image from 'next/image';
import { ArrowRight } from 'react-feather';
import leadImage from '~/public/images/ebook-teaser.png';
import pathMap from '~/shared/pathMap';
import Link from 'next/link';

const LeadMagnet = ({ theme, name }) => {
  const { t } = useTranslation();

  const buttonClass = theme === 'purple' ? 'btn-secondary' : 'btn-primary';

  return (
    <section
      className={classNames(styles['lead-magnet'], 'py-6 md:py-10', styles[theme])}
      id={`lead-magnet-${name}`}
    >
      <div className="flex flex-col items-center lg:flex-row justify-center">
        <Image
          src={leadImage}
          alt={t('leadMagnetTitle')}
          title={t('leadMagnetTitle')}
          className="w-64 h-64 lg:w-80 lg:h-80 block md:hidden lg:block object-contain"
        />
        <div className="px-3 md:max-w-lg">
          <h2 className="text-3xl text-left mb-8 font-medium">{t('leadMagnetTitle')}</h2>
          <h3 className="text-white font-normal text-base">{t('leadMagnetText')}</h3>
          <ul className="list-none p-0 space-y-2 text-base">
            <li className="font-normal flex">
              <ArrowRight className="mr-2" /> {t('leadMagnetBenefitOne')}
            </li>
            <li className="font-normal flex">
              <ArrowRight className="mr-2" /> {t('leadMagnetBenefitTwo')}
            </li>
            <li className="font-normal flex">
              <ArrowRight className="mr-2" /> {t('leadMagnetBenefitThree')}
            </li>
          </ul>
          <div className="flex justify-center mt-8">
            <Link href={pathMap.newsletter.as()} className="self-center">
              <button
                type="submit"
                className={classNames(
                  styles['lead-magnet-button'],
                  'input-group-addon btn btn-lg mt-2',
                  buttonClass
                )}
              >
                {t('leadMagnetButton')}
              </button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

LeadMagnet.propTypes = {
  theme: PropTypes.string,
  name: PropTypes.string.isRequired
};

LeadMagnet.defaultProps = {
  theme: ''
};

export default LeadMagnet;
