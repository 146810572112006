import type { IconProps } from 'react-feather';

export const CallIcon: React.FC<IconProps> = (props) => (
  <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M61.6842 22.7893L44.9219 34.7624L61.6842 46.7354V22.7893Z"
      stroke="currentColor"
      stroke-width="4.8"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M40.13 18H13.7892C11.1442 18 9 20.1442 9 22.7892V46.7353C9 49.3804 11.1442 51.5246 13.7892 51.5246H40.13C42.775 51.5246 44.9192 49.3804 44.9192 46.7353V22.7892C44.9192 20.1442 42.775 18 40.13 18Z"
      stroke="currentColor"
      stroke-width="4.8"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const ToolIcon: React.FC<IconProps> = (props) => (
  <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M41.0163 20.7753C40.515 21.2868 40.2342 21.9744 40.2342 22.6906C40.2342 23.4068 40.515 24.0944 41.0163 24.6059L45.3941 28.9837C45.9056 29.485 46.5932 29.7658 47.3094 29.7658C48.0256 29.7658 48.7132 29.485 49.2247 28.9837L59.5399 18.6685C60.9157 21.7089 61.3323 25.0963 60.7341 28.3794C60.1359 31.6625 58.5514 34.6854 56.1916 37.0451C53.8319 39.4048 50.8091 40.9894 47.5259 41.5876C44.2428 42.1857 40.8554 41.7692 37.815 40.3934L18.9084 59.3C17.8199 60.3885 16.3436 61 14.8042 61C13.2648 61 11.7885 60.3885 10.7 59.3C9.61151 58.2115 9 56.7352 9 55.1958C9 53.6564 9.61151 52.1801 10.7 51.0916L29.6067 32.185C28.2308 29.1446 27.8143 25.7572 28.4124 22.4741C29.0106 19.1909 30.5952 16.1681 32.9549 13.8084C35.3146 11.4486 38.3375 9.8641 41.6206 9.26591C44.9037 8.66772 48.2911 9.08429 51.3315 10.4601L41.0163 20.7753Z"
      stroke="currentColor"
      stroke-width="4.8"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const RocketIcon: React.FC<IconProps> = (props) => (
  <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M31.5309 21.2255C31.5646 21.1747 31.597 21.1258 31.6279 21.0795C39.8818 8.9155 52.112 8.45252 59.6423 9.84035L59.6455 9.84095C59.9898 9.90389 60.2364 10.1571 60.2957 10.4855L60.2971 10.4929C61.6839 18.0312 61.2271 30.2639 49.0536 38.5172L49.0535 38.5172L49.0435 38.5241C48.8125 38.6825 48.4308 38.883 48.1034 39.0414L46.8052 39.6695V41.1117V50.2235C46.8052 52.2067 45.7649 54.0318 44.0615 55.0434C44.0612 55.0436 44.0609 55.0438 44.0606 55.044L34.3589 60.7882L34.3552 60.7904C34.256 60.8494 34.1288 60.854 34.0223 60.799C33.924 60.7381 33.8614 60.6275 33.8614 60.5086V48.7542V45.5416L30.8202 46.577C29.2811 47.1009 27.9457 47.546 26.9422 47.8697C26.5598 47.9922 26.1214 47.8952 25.8061 47.5861L22.4324 44.1141L22.3761 44.0562L22.3159 44.0024C22.1247 43.8314 21.9458 43.4257 22.0525 42.9215C22.3713 41.9781 22.7782 40.8108 23.3465 39.4849L24.7205 36.2789H21.2324H9.63157C9.50776 36.2789 9.40027 36.2135 9.34394 36.1145L9.34382 36.1143C9.28373 36.0088 9.28666 35.8832 9.34513 35.7849L9.34647 35.7826L15.1001 26.0787L15.1011 26.077C16.1359 24.3284 17.9898 23.2364 19.9166 23.2364H28.9298H30.0579L30.7485 22.3444C31.0303 21.9804 31.3094 21.5594 31.5309 21.2255ZM47.234 29.4936C50.8933 29.4936 53.9199 26.6337 53.9199 22.8076C53.9199 19.2464 50.9608 16.1217 47.234 16.1217C43.5071 16.1217 40.548 19.2464 40.548 22.8076C40.548 26.6337 43.5746 29.4936 47.234 29.4936Z"
      stroke="currentColor"
      stroke-width="4.6"
    />
  </svg>
);

export const CheckIcon: React.FC<IconProps> = (props) => (
  <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M59.9979 32.4416V34.7645C59.9948 40.209 58.2318 45.5067 54.9719 49.8674C51.712 54.2281 47.1297 57.4182 41.9086 58.9619C36.6875 60.5056 31.1073 60.3202 26.0002 58.4334C20.893 56.5466 16.5326 53.0594 13.5693 48.492C10.606 43.9245 9.19845 38.5215 9.55668 33.0888C9.91492 27.656 12.0197 22.4847 15.5571 18.3459C19.0946 14.2071 23.8751 11.3227 29.1858 10.1228C34.4964 8.92291 40.0527 9.47187 45.0259 11.6878M60 14.5656L34.752 39.8388L27.1776 32.2644"
      stroke="currentColor"
      stroke-width="5.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const CartIcon: React.FC<IconProps> = (props) => (
  <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9 10H18.4547L24.7893 41.6495C25.0055 42.7377 25.5975 43.7153 26.4617 44.411C27.326 45.1067 28.4074 45.4763 29.5166 45.455H52.4915C53.6008 45.4763 54.6822 45.1067 55.5464 44.411C56.4107 43.7153 57.0027 42.7377 57.2188 41.6495L61.0007 21.8183H20.8183M30.2742 57.2735C30.2742 58.5789 29.216 59.6371 27.9105 59.6371C26.6051 59.6371 25.5469 58.5789 25.5469 57.2735C25.5469 55.968 26.6051 54.9098 27.9105 54.9098C29.216 54.9098 30.2742 55.968 30.2742 57.2735ZM56.2742 57.2735C56.2742 58.5789 55.216 59.6371 53.9105 59.6371C52.6051 59.6371 51.5469 58.5789 51.5469 57.2735C51.5469 55.968 52.6051 54.9098 53.9105 54.9098C55.216 54.9098 56.2742 55.968 56.2742 57.2735Z"
      stroke="currentColor"
      stroke-width="4.72734"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
