import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import Link from 'next/link';
import React, { useContext, useEffect, useState } from 'react';
import { Droplet } from 'react-feather';
import Skeleton from 'react-loading-skeleton';
import { twMerge } from 'tailwind-merge';
import { RequestApi } from '~/lib/api-client';
import { getUsecaseCategoryIcon, IconPropsWithRequiredSize } from '~/lib/featherIcons';
import { useScrollButtons } from '~/lib/hooks/useScrollButtons';
import { useBreakpoint } from '~/lib/hooks/useTailwindBreakpoints';
import { getScreens } from '~/lib/tailwind';
import pathMap from '~/shared/pathMap';
import type { UsecaseCategory } from '~/types';
import type { UsecaseWithPrice } from 'shared/types';
import { LinkButton } from '../button';
import { CategorySlider } from '../category-slider';
import { ChevronButton } from '../chevron-button';
import { Price, PriceToText } from '../price';
import { PageSection } from '../page-section/PageSection';
import { TranslationPricesContext } from '~/pages';
import { useRouter } from 'next/router';
import { dateConsideredNew } from '~/lib/helpers';
import type { ISbStoryData } from '@storyblok/react';

const itemClass = 'flex flex-col flex-none no-underline';

const getSizingClassNames = (isFullWidth: boolean) => (isFullWidth ? 'w-full' : `w-40 md:w-48 lg:w-72`);

export const UsecaseItem = ({
  className,
  isFullWidth = false,
  ...usecase
}: ISbStoryData<UsecaseWithPrice> & {
  isInitialData: boolean;
  className?: string;
  isFullWidth?: boolean;
}) => {
  const { t } = useTranslation('common');

  return (
    <Link
      key={usecase.id}
      href={pathMap.solution.as([usecase.slug])}
      className={classNames(itemClass, className, getSizingClassNames(isFullWidth))}
    >
      <div className="w-full aspect-square relative">
        {usecase.content.stageImage && (
          <Image
            src={usecase.content.stageImage?.filename}
            className="rounded-lg object-cover"
            alt={usecase.content.title}
            fill
            sizes={`(min-width: ${getScreens().lg}) 288px, 192px`}
          />
        )}
        <div
          className={classNames(
            'absolute right-4 top-4 bg-brand-orange px-2 py-1 rounded-md text-white text-sm uppercase',
            {
              hidden: !dateConsideredNew(new Date(usecase.created_at))
            }
          )}
        >
          {t('mp-ads.new-icon')}
        </div>
      </div>
      <div className="text-center md:text-left">
        <div className="text-base text-brand-darkPurple mt-1">{usecase.content.title}</div>
        <div className="text-sm text-gray-500">{usecase.content.vendorName ?? usecase.content.vendor}</div>
        <div className="hidden md:block text-sm text-black">
          <Price price={usecase.content.price} />
        </div>
      </div>
    </Link>
  );
};

export function UsecaseListScrollable({
  usecases,
  isLoading,
  isInitialData = true,
  scrollableOnMobile = true,
  addSpacing = false
}: {
  isLoading?: boolean;
  usecases: ISbStoryData<UsecaseWithPrice>[];
  isInitialData?: boolean;
  scrollableOnMobile?: boolean;
  addSpacing?: boolean;
}) {
  const { isLg } = useBreakpoint('lg');
  const { onLeftArrowClick, onRightArrowClick, scrollContainerRef, showLeftButton, showRightButton } =
    useScrollButtons();
  return (
    <div className={twMerge('relative', addSpacing && '2xl:container 2xl:px-16')}>
      <div className="absolute hidden lg:block w-full top-1/2 transform -translate-y-1/2 z-30 -mt-12">
        {showLeftButton && <ChevronButton className="absolute left-[1rem]" onClick={onLeftArrowClick} />}
        {showRightButton && (
          <ChevronButton
            className={twMerge('absolute right-[1rem]', addSpacing && '2xl:right-[9rem]')}
            onClick={onRightArrowClick}
            direction="right"
          />
        )}
      </div>
      <div
        ref={scrollContainerRef}
        className={twMerge(
          'flex gap-x-2 overflow-x-auto py-4 relative hide-scrollbar',
          !scrollableOnMobile && !isLg && 'grid grid-cols-2 justify-items-center'
        )}
      >
        {isLoading
          ? Array.from({ length: 5 }, (_, i) => i).map((index) => (
              <div
                key={index}
                className={twMerge(
                  classNames(getSizingClassNames(false), itemClass),
                  !scrollableOnMobile && index >= 4 && 'hidden'
                )}
              >
                <Skeleton className="aspect-square w-full" />
              </div>
            ))
          : usecases.map((usecase, i) => (
              <UsecaseItem
                key={usecase.id}
                className={!scrollableOnMobile && i >= 4 ? 'hidden md:flex' : undefined}
                isInitialData={isInitialData}
                {...usecase}
              />
            ))}
      </div>
    </div>
  );
}

export function UsecaseListGrid({
  usecases,
  isLoading,
  isInitialData = true
}: {
  isLoading?: boolean;
  usecases: ISbStoryData<UsecaseWithPrice>[];
  isInitialData?: boolean;
}) {
  const { isMd } = useBreakpoint('md');
  const [visibleUsecases, setVisibleUsecases] = useState(4);

  useEffect(() => setVisibleUsecases(isMd ? 8 : 4), [isMd]);

  return (
    <div className="relative">
      <div
        className={twMerge('px-3 md:px-0 mt-4 grid grid-cols-2 gap-x-10 gap-y-10 md:grid-cols-4 md:gap-x-5')}
      >
        {isLoading
          ? Array.from({ length: visibleUsecases }, (_, i) => i).map((index) => (
              <div key={index} className={twMerge(classNames(getSizingClassNames(false), itemClass))}>
                <Skeleton className="aspect-square w-full" />
              </div>
            ))
          : usecases.map((usecase, index) => (
              <UsecaseItem
                key={usecase.id}
                className={`${index + 1 > visibleUsecases && 'hidden'}`}
                isInitialData={isInitialData}
                isFullWidth
                {...usecase}
              />
            ))}
      </div>
    </div>
  );
}

type Props = {
  categories: Array<UsecaseCategory>;
  initialUsecases: Array<ISbStoryData<UsecaseWithPrice>>;
  limit?: number;
};

export const UsecaseSection = ({ categories, initialUsecases, limit }: Props) => {
  const { t } = useTranslation();
  const [selectedCategorySlug, setSelectedCategorySlug] = useState(categories[0].slug);

  const currentCategory = categories.find((category) => category.slug === selectedCategorySlug);

  const isInitialCategory = selectedCategorySlug === categories[0].slug;

  const { data: currentUsecases, isLoading } = useQuery(
    ['usecase', selectedCategorySlug],
    () =>
      RequestApi<Array<ISbStoryData<UsecaseWithPrice>>>({
        method: 'GET',
        url: `/usecasesByCategoryId/${currentCategory?.id}?limit=${limit}`
      }),
    {
      initialData: selectedCategorySlug === categories[0].slug ? initialUsecases : undefined,
      refetchOnMount: false,
      // no need to refetch
      refetchOnWindowFocus: false
    }
  );

  const translationPlacerholderPrices = useContext(TranslationPricesContext);
  const router = useRouter();
  const { locale } = router;
  const subTitlePrice = PriceToText({
    locale: locale || 'en-GB',
    price: translationPlacerholderPrices['landing-automate-factory-95p-under'].price,
    countryCode: translationPlacerholderPrices['landing-automate-factory-95p-under'].countryCode,
    t
  });

  return (
    <PageSection
      title={t('landing:usecaseTitle')}
      sub={t('landing:usecaseSubTitle', { price: subTitlePrice })}
    >
      <CategorySlider
        categories={categories.map(({ id, title, icon, slug }) => ({
          key: id,
          slug,
          title,
          iconOverride: getUsecaseCategoryIcon({ icon }) ?? (Droplet as React.FC<IconPropsWithRequiredSize>)
        }))}
        currentCategorySlug={selectedCategorySlug}
        onChange={setSelectedCategorySlug}
      />
      <UsecaseListGrid
        usecases={currentUsecases ?? []}
        isInitialData={isInitialCategory}
        isLoading={isLoading}
      />
      <div className="flex items-center my-12">
        <Link
          href={pathMap.solutions.as([], {
            category: selectedCategorySlug
          })}
          passHref
          legacyBehavior
        >
          <LinkButton className="mx-auto w-auto">{t('landing:discoverUsecases')}</LinkButton>
        </Link>
      </div>
    </PageSection>
  );
};
