import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { ArrowRight } from 'react-feather';
import trackEvent from '~/lib/analytics.service';
import { buildAssetUrl } from '~/lib/assetPathHelper';

export const ChatSection = () => {
  const { t } = useTranslation();

  return (
    <a
      href="https://chat.rbtx.de"
      target={'_blank'}
      rel="noreferrer"
      className="hidden md:block pt-12 p-20 bg-gradient-radial from-[#7c49af] to-[#572C86]"
      onClick={() => trackEvent('chat', 'click')}
    >
      <div className="flex flex-col items-center gap-y-12">
        <h2 className="text-white text-5xl font-semibold items-center">
          <span className="bg-white rounded-lg rounded-br-none text-[#7c49af] px-3 py-1">Hey_</span>{' '}
          {t('landing:chat.title')}
        </h2>
        <div className="relative">
          <div className="absolute flex gap-x-3 text-white font-semibold drop-shadow-lg bottom-0 p-5 text-5xl">
            {t('landing:chat.cta')}
            <div className="bg-brand-orange size-12 rounded-full flex justify-center">
              <ArrowRight className="text-white self-center" size={28} />
            </div>
          </div>
          <Image
            className="rounded-xl"
            src={buildAssetUrl('/images/chat/bg.png')}
            alt="RBTX AI Chat"
            width={1000}
            height={551}
          />
        </div>
      </div>
    </a>
  );
};
