import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import Link from 'next/link';
import { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useProducts } from '~/lib/hooks/useProducts';
import { getScreens } from '~/lib/tailwind';
import pathMap from '~/shared/pathMap';
import type { Category, Product } from '~/types';
import { LinkButton } from '../button';
import { CategorySlider } from '../category-slider';
import { Price } from '../price';
import { PageSection } from '../page-section/PageSection';
import { dateConsideredNew } from '~/lib/helpers';

export const getGridItemClass = (index: number, fillGrid?: boolean) =>
  classNames(
    'flex flex-col justify-self-center aspect-square w-full hover:no-underline link-group-hover',
    !fillGrid && 'max-w-[250px] md:max-w-xs',
    {
      // cut off after 4 products on mobile only devices
      'hidden md:flex': index >= 4 && index < 8,
      'hidden xl:flex': index >= 8
    }
  );

export type ProductGridItemProps = Pick<
  Product,
  'id' | 'name' | 'price' | 'image' | 'vendor' | 'categorySlug' | 'slug' | 'createdAt'
>;

export const ProductGridItem = ({
  name,
  image,
  index,
  vendor,
  categorySlug,
  slug,
  variantSlug,
  price,
  fillGrid = false,
  isDisabled = false,
  createdAt
}: ProductGridItemProps & {
  index: number;
  hasInvalidData?: boolean;
  fillGrid?: boolean;
  invalidatePrice?: boolean;
  isDisabled?: boolean;
  variantSlug?: string;
}) => {
  const { t } = useTranslation('common');

  const productPathWithOptionalVariant = () => {
    if (categorySlug == 'bundles') {
      return pathMap.bundle.as([categorySlug, slug]);
    } else if (variantSlug) {
      return pathMap.productVariant.as([categorySlug, slug, variantSlug]);
    }

    return pathMap.product.as([categorySlug, slug]);
  };

  return (
    <Link
      href={productPathWithOptionalVariant()}
      className={getGridItemClass(index, fillGrid)}
      style={
        isDisabled
          ? {
              filter: 'grayscale(100%)'
            }
          : undefined
      }
    >
      <div className="bg-[#FAFAFA] flex justify-center py-3 lg:py-10 rounded-md aspect-square w-full relative">
        <div className="relative w-[80%]">
          <Image
            src={image}
            alt={name}
            fill
            className="object-contain group-image"
            sizes={`(min-width: ${getScreens().md}) 320px, 250px`}
          />
        </div>
        <div
          className={classNames(
            'absolute right-4 top-4 bg-brand-orange px-2 py-1 rounded-md text-white text-sm uppercase',
            {
              hidden: !dateConsideredNew(new Date(createdAt))
            }
          )}
        >
          {t('mp-ads.new-icon')}
        </div>
      </div>
      <span className="text-brand-darkPurple text-base mt-1 overflow-x-hidden text-ellipsis whitespace-nowrap">
        {name}
      </span>
      <span className="text-sm text-gray-500">{vendor}</span>
      <span className="text-base">
        <Price price={price} />
      </span>
    </Link>
  );
};

type Props = {
  categories: Array<Category>;
  products: Array<Product>;
};

export const LOADING_PRODUCT_COUNT = 10;

export const ProductsSection = ({ categories, products }: Props) => {
  const { t } = useTranslation();
  const [currentCategorySlug, setCurrentCategorySlug] = useState(categories[0].slug);

  const { products: currentProducts, isFetching } = useProducts({
    categorySlug: currentCategorySlug,
    useInitialData: currentCategorySlug === categories[0]?.slug,
    initialProducts: products
  });

  // impossible case
  if (!currentCategorySlug) return null;

  return (
    <PageSection
      title={t('landing:productsTitle')}
      sub={t('landing:productsSubTitle')}
      className="py-3 lg:px-16"
    >
      <CategorySlider
        categories={categories}
        currentCategorySlug={currentCategorySlug}
        onChange={setCurrentCategorySlug}
        centered
      />
      <div className="grid grid-cols-2 md:grid-cols-4 xl:grid-cols-5 py-4 gap-6 px-4 text-center">
        {isFetching
          ? Array.from({ length: LOADING_PRODUCT_COUNT }).map((_, i) => (
              <div key={i} className={getGridItemClass(i)}>
                <Skeleton className="aspect-square w-full" />
              </div>
            ))
          : currentProducts?.slice(0, LOADING_PRODUCT_COUNT).map((product, i) => (
              // first page has invalid data because its static data°
              // and the static data doesn't reflex ip-based pricing
              <ProductGridItem key={`${product.categorySlug}-${product.id}`} index={i} {...product} />
            ))}
      </div>
      <div className="flex justify-center my-4">
        <Link href={pathMap.components.as([currentCategorySlug])} passHref legacyBehavior>
          <LinkButton>{t('landing:seeAllProducts')}</LinkButton>
        </Link>
      </div>
    </PageSection>
  );
};
