import classNames from 'classnames';
import type React from 'react';
import { getIconByCategorySlug } from '~/lib/categoryIcons';
import type { IconPropsWithRequiredSize } from '~/lib/featherIcons';
import { useScrollButtons } from '~/lib/hooks/useScrollButtons';
import { ChevronButton } from '../chevron-button';

export type CategorySliderEntryProps = {
  key: string;
  slug: string;
  title: string;
  iconOverride?: React.FC<IconPropsWithRequiredSize>;
};

type Props = {
  currentCategorySlug: string;
  onChange: (slug: string) => void;
  categories: Array<CategorySliderEntryProps>;
  centered?: boolean;
};

export const CategorySlider = ({ categories, currentCategorySlug, onChange, centered }: Props) => {
  const { scrollContainerRef, onLeftArrowClick, onRightArrowClick, showLeftButton, showRightButton } =
    useScrollButtons(200);
  return (
    <div className="relative px-2">
      <div className="absolute hidden lg:block w-full top-1/2 -translate-y-1/2 z-30 -mt-4">
        {showLeftButton && <ChevronButton className="absolute left-[1rem]" onClick={onLeftArrowClick} />}
        {showRightButton && (
          <ChevronButton className="absolute right-[1rem]" onClick={onRightArrowClick} direction="right" />
        )}
      </div>
      <div
        ref={scrollContainerRef}
        className={classNames('flex overflow-x-auto hide-scrollbar', centered && '2xl:justify-center')}
      >
        {categories.map(({ key, slug, title, iconOverride }) => {
          const CategoryIcon = iconOverride ?? getIconByCategorySlug(key);

          return (
            <div
              key={slug}
              role="button"
              onClick={() => onChange(slug)}
              className={classNames(
                'flex flex-col items-center py-2 mr-10 flex-none border-b-2 border-solid border-0 hover:border-brand-darkPurple/40',
                currentCategorySlug === slug ? 'border-brand-darkPurple' : 'border-transparent'
              )}
            >
              <span className="text-brand-darkPurple m-1 w-6 h-6">
                <CategoryIcon size={24} />
              </span>
              <span className="text-xs">{title}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};
