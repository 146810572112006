import Link from 'next/link';
import PathMap from '~/shared/pathMap';
import Image from 'next/image';
import type { Partner } from '~/types';
import classNames from 'classnames';
import { useEffect, useRef } from 'react';

type PartnerListProps = {
  partners: Array<Partner>;
  className?: string;
};

const PartnerList = ({ partners, className }: PartnerListProps) => {
  const partnerListRef = useRef<HTMLUListElement>(null);
  const partnerList2Ref = useRef<HTMLUListElement>(null);

  useEffect(() => {
    const animDuration = partners.length * 2.5;

    if (partnerListRef.current) {
      partnerListRef.current.style.setProperty('animation-duration', `${animDuration}s`, 'important');
    }
    if (partnerList2Ref.current) {
      partnerList2Ref.current.style.setProperty('animation-duration', `${animDuration}s`, 'important');
    }
  }, [partners]);

  const partnerList = partners.map((partner) => {
    return (
      <div key={partner.slug} className="flex justify-center md:h-[80px] px-5">
        <Link href={PathMap.partner.as([partner.slug])} className="w-32 h-[80px] relative">
          <Image src={partner.logo} alt={partner.name} sizes="128px" className="object-contain" fill />
        </Link>
      </div>
    );
  });

  return (
    <div
      className={classNames('flex whitespace-no-wrap overflow-x-hidden overflow-y-hidden py-5', className)}
    >
      <div className="group relative">
        <ul ref={partnerListRef} className="flex group-hover:pause animate-marquee">
          {partnerList}
        </ul>
        <ul ref={partnerList2Ref} className="flex absolute top-0 group-hover:pause animate-marquee2">
          {partnerList}
        </ul>
      </div>
    </div>
  );
};

export default PartnerList;
