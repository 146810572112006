import { useEffect, useRef, useState } from 'react';

export const useScrollButtons = (scrollIncrement = 500) => {
  const [showLeftButton, setShowLeftButton] = useState(false);
  const [showRightButton, setShowRightButton] = useState(false);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!scrollContainerRef.current) return;
    const containerRef = scrollContainerRef.current;
    const onScroll = () => {
      const { scrollLeft, scrollWidth, clientWidth } = containerRef;
      setShowLeftButton(scrollLeft > 0);
      setShowRightButton(scrollLeft < scrollWidth - clientWidth);
    };

    // check whether the container needs scrollbars when resizing it
    const resizeObserver = new ResizeObserver(() => onScroll());
    resizeObserver.observe(containerRef);

    containerRef.addEventListener('scroll', onScroll);
    return () => {
      resizeObserver.disconnect();
      containerRef.removeEventListener('scroll', onScroll);
    };
  }, []);

  // initially show right button if the element is scrollable
  useEffect(() => {
    if (!scrollContainerRef.current) return;
    if (scrollContainerRef.current.scrollWidth > scrollContainerRef.current.clientWidth) {
      setShowRightButton(true);
    }
  }, []);

  const onRightArrowClick = () => {
    if (!scrollContainerRef.current) return;
    scrollContainerRef.current.scroll({
      left: scrollContainerRef.current.scrollLeft + scrollIncrement,
      behavior: 'smooth'
    });
  };
  const onLeftArrowClick = () => {
    if (!scrollContainerRef.current) return;
    scrollContainerRef.current.scroll({
      left: scrollContainerRef.current.scrollLeft - scrollIncrement,
      behavior: 'smooth'
    });
  };

  return { onRightArrowClick, onLeftArrowClick, scrollContainerRef, showLeftButton, showRightButton };
};
