import { twMerge } from 'tailwind-merge';
import { useCountryCode } from '~/lib/hooks/useCountryCode';
import { PhoneCall, X } from 'react-feather';
import { useTranslation } from 'next-i18next';
import { useBreakpoint } from '~/lib/hooks/useTailwindBreakpoints';
import { Fragment, useState } from 'react';
import { Transition, Dialog } from '@headlessui/react';

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const Modal = ({ isOpen, onClose }: ModalProps) => {
  const { t } = useTranslation();

  return (
    <Transition
      show={isOpen}
      enter="transition duration-100 ease-out"
      enterFrom="transform scale-95 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
      as={Fragment}
    >
      <Dialog open={isOpen} onClose={onClose} className="absolute w-screen inset-0 z-[1200]">
        <div className="fixed inset-0 bg-black/70" aria-hidden="true" />
        <div className="fixed inset-0 p-3 overflow-y-auto flex items-center justify-center">
          <div className="flex items-center justify-center xl:mt-20 md:min-h-0">
            <Dialog.Panel
              as="div"
              className="relative w-[300px] rounded-lg bg-white shadow-md md:min-h-0 overflow-y-scroll"
            >
              <button className="bg-transparent absolute right-4 top-4 border-0" onClick={onClose}>
                <X />
              </button>
              <div className="pt-6">
                <div className="text-xl md:text-2xl font-medium text-black mx-7 -mt-2">
                  {t('landing:hotline.title')}
                </div>
                <div className="flex flex-col items-center my-10">
                  <PhoneCall />
                  <a className="mt-3 text-brand-purple underline" href={`tel:${t('landing:hotline.tel')}`}>
                    {t('landing:hotline.tel')}
                  </a>
                  <div className="text-xs mt-1">{t('landing:hotline.time')}</div>
                </div>
              </div>
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

type Props = {
  className?: string;
};

export const HotlineWidget = ({ className }: Props) => {
  const country = useCountryCode();
  const { t } = useTranslation();
  const { isLg } = useBreakpoint('lg');
  const [modalOpen, setModalOpen] = useState(false);

  if (country !== 'DE') {
    return <></>;
  }

  return (
    <a
      href={isLg ? `tel:${t('landing:hotline.tel')}` : undefined}
      onClick={
        !isLg
          ? () => {
              setModalOpen(!modalOpen);
            }
          : undefined
      }
      className={twMerge(
        className,
        'flex items-center bg-brand-orange px-6 py-3 rounded-s-full translate-x-[calc(100%-64px-8px)] lg:hover:translate-x-0 transition ease-in-out'
      )}
    >
      <span className="mr-6">
        <PhoneCall />
      </span>
      <div className="flex flex-col">
        <div className="text-base">{t('landing:hotline.tel')}</div>
        <div className="text-xs">{t('landing:hotline.time')}</div>
      </div>
      <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)} />
    </a>
  );
};
