import classNames from 'classnames';

type PageSectionProps = {
  title: string;
  sub?: string;
  className?: string;
  containerClassName?: string;
  children: React.ReactNode;
};

export const PageSection = ({ title, sub, className, containerClassName, children }: PageSectionProps) => {
  return (
    <section className={classNames('pb-12 flex flex-col md:py-12 md:pt-16', className)}>
      <div className="p-5 text-center">
        <h1 className="font-inter font-medium text-3xl text-black lg:text-5xl">{title}</h1>
        {sub && <h2 className="mt-5 font-inter text-base text-gray-700">{sub}</h2>}
      </div>
      <div className={classNames('container px-2 md:px-6 mt-7', containerClassName)}>{children}</div>
    </section>
  );
};
