import classNames from 'classnames';
import type { ButtonHTMLAttributes } from 'react';
import { ChevronRight, ChevronLeft } from 'react-feather';

type Props = Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'children'> & { direction?: 'left' | 'right' };

export const ChevronButton = ({ direction = 'left', className, ...props }: Props) => (
  <button
    {...props}
    className={classNames(
      'rounded-full h-8 w-8 bg-white text-brand-darkPurple flex items-center justify-center border border-brand-darkPurple border-solid',
      className
    )}
  >
    {direction === 'right' ? <ChevronRight /> : <ChevronLeft />}
  </button>
);
