import classNames from 'classnames';
import { buildAssetUrl } from '~/lib/assetPathHelper';
import Image from 'next/image';
import { ArrowRight } from 'react-feather';
import pathMap from '~/shared/pathMap';
import Link from 'next/link';
import { PageSection } from '../page-section/PageSection';
import { useTranslation } from 'next-i18next';
import { useMachinePlannerUrl } from '~/lib/hooks/useMachinePlannerUrl';
import { useCountryCode } from '~/lib/hooks/useCountryCode';

type CardProps = {
  title: string;
  sub?: string;
  href: string;
  className?: string;
  children: React.ReactNode;
  newIcon?: boolean;
  disabled?: boolean;
};

const Card = ({ title, sub, href, className, children, newIcon, disabled }: CardProps) => {
  const { t } = useTranslation();
  return (
    // TODO: Find out why the cards don't force full height to match the other cards (the tallest one)
    <Link
      href={href}
      className={classNames(
        'w-96 h-[475px]  flex flex-col rounded-md shadow-lg transition ease-in-out hover:scale-[1.01] self-center bg-white',
        disabled && 'cursor-not-allowed pointer-events-none',
        className
      )}
    >
      <div
        className={classNames(
          'h-56 bg-gradient-radial from-[#7c49af] to-[#572C86] rounded-t-md flex items-end',
          disabled && '[&_img]:opacity-50'
        )}
      >
        {children}
      </div>
      <div className={classNames('grow m-5', disabled && 'opacity-50')}>
        <div className="flex">
          <h3 className="text-2xl font-semibold text-[#2D3748]">{title}</h3>
          {newIcon && !disabled && (
            <Image
              src={buildAssetUrl(`/images/mp-planner/${t('common:mp-ads.new-icon')}.svg`)}
              className="ml-1"
              width="40"
              height="20"
              alt="New"
            />
          )}
        </div>
        {sub && <h3 className="text-lg text-[#2D3748] mt-1">{sub}</h3>}
      </div>
      <div className="m-5 flex">
        {disabled ? (
          <div className="ml-auto px-2 py-[2px] bg-brand-orange text-white font-medium rounded-[3px]">
            {t('landing:free-services.cards.machine-planner.comingsoon').toUpperCase()}
          </div>
        ) : (
          <div className="bg-brand-orange size-12 rounded-full flex justify-center">
            <ArrowRight className="text-white self-center" size={28} />
          </div>
        )}
      </div>
    </Link>
  );
};

type ServicesSectionProps = {
  className?: string;
};

const ServicesSection = ({ className }: ServicesSectionProps) => {
  const machinePlannerUrl = useMachinePlannerUrl(); // temporary until we have a better way to determine if the machine planner is supported in current region
  const { t } = useTranslation();
  const country = useCountryCode();

  return (
    <PageSection
      title={t('landing:free-services.title')}
      sub={t('landing:free-services.sub')}
      className={classNames('', className)}
    >
      <div className="mx-auto flex flex-col lg:flex-row justify-center mt-5 gap-3 md:gap-10">
        <Card
          href={pathMap.rbtxpert.as()}
          className=""
          title={t('landing:free-services.cards.expert.title')}
          sub={t('landing:free-services.cards.expert.sub')}
        >
          <Image
            className="w-[95%] h-auto mx-auto"
            src={buildAssetUrl(`/images/free-services/expert${country === 'CN' ? '-cn' : ''}.png`)}
            alt="RBTXpert"
            sizes="512px"
            width={128}
            height={128}
          />
        </Card>
        <Card
          href={pathMap.customerTestingArea.as()}
          className=""
          title={t('landing:free-services.cards.customer-testing-area.title')}
          sub={t('landing:free-services.cards.customer-testing-area.sub')}
        >
          <Image
            className="w-[80%] mx-auto"
            src={buildAssetUrl(`/images/free-services/test${country === 'CN' ? '-cn' : ''}.png`)}
            alt="Machine Planner"
            sizes="512px"
            width={128}
            height={128}
          />
        </Card>
        <Card
          href={pathMap.machinePlanner.as([''])}
          className={''}
          title={t('landing:free-services.cards.machine-planner.title')}
          sub={t('landing:free-services.cards.machine-planner.sub')}
          newIcon={true}
          disabled={machinePlannerUrl === null}
        >
          <Image
            className="w-[70%] mx-auto"
            src={buildAssetUrl('/images/free-services/machine_planner.png')}
            alt="Machine Planner"
            sizes="512px"
            width={128}
            height={128}
          />
        </Card>
      </div>
    </PageSection>
  );
};

export default ServicesSection;
