import Script from 'next/script';

const OrganizationJsonLd = () => {
  const html: any = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'RBTX',
    url: 'https://www.rbtx.com',
    logo: 'https://rbtx.com/images/logo.svg'
  };

  return (
    <Script
      id="organization-jsonld" // possible to have multiple?
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(html) }}
    />
  );
};

export default OrganizationJsonLd;
