import classNames from 'classnames';
import Image from 'next/image';
import { buildAssetUrl } from '~/lib/assetPathHelper';

type AwardsSectionProps = {
  className?: string;
};

const awards = [
  {
    alt: 'iF DESIGN AWARD Gold 2016',
    src: buildAssetUrl('/images/awards/if-gold-award-2016.jpg')
  },
  {
    alt: 'iF DESIGN AWARD 2021',
    src: buildAssetUrl('/images/awards/if-award-2021.png')
  },
  {
    alt: 'Rheinland Genial',
    src: buildAssetUrl('/images/awards/rheinland-genial.png')
  },
  {
    alt: 'red dot design award',
    src: buildAssetUrl('/images/awards/red-dot.png')
  },
  {
    alt: 'GERMAN DESIGN AWARD',
    src: buildAssetUrl('/images/awards/german-design-award.png')
  }
];

const AwardsSection = ({ className }: AwardsSectionProps) => {
  return (
    <section
      className={classNames(
        'container mb-16 mt-8 px-2 lg:px-32 flex flex-wrap justify-evenly lg:flex-nowrap gap-y-5',
        className
      )}
    >
      {awards.map((award, i) => (
        <div key={i} className="w-1/3 p-2 md:p-0">
          <Image
            src={award.src}
            alt={award.alt}
            className="h-20 object-contain relative"
            sizes="512px"
            fill
          />
        </div>
      ))}
    </section>
  );
};

export default AwardsSection;
